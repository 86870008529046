.secondNav{
    height: 10vh;
    /* position: fixed; */
    background-color: white;
    overflow: hidden;
}

.secondNavButton{
    background-color: #ecb830;
    border: none;
    font-size: 1rem;
    height: 3rem;
    width: 10rem;
    border-radius: 10rem;
    /* margin: 0px 0.5rem; */
    margin: 0px 1rem;
    cursor: pointer;

}

.secondNavButtonShadow{
    box-shadow: 10px 10px 20px 10px rgb(228, 244, 250);
    border: none;
    font-size: 1rem;
    height: 3rem;
    width: 10rem;
    border-radius: 10rem;
    background-color: transparent;
    margin: 0px 1rem;
    
    cursor: pointer;
}