.content{
    /* height: auto; */
    /* margin-top: 20vh; */
    background-color: rgb(14, 14, 14);
    
}
.ant-image-preview-wrap{
    z-index: 99999999999999999 !important;
    background-color: rgb(10, 0, 0) !important;
    color: wheat;
}