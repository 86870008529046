.flex{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.mainNavbar{
    height: 9vh;
    background-color: var(--navbarBackground);
    position: fixed;
    z-index: 99999999999;
    background: rgba(0, 0, 0,0.1);
}

.menuOptions span {
    cursor: pointer;
    color: var(--white);
    font-weight: bold;
}
.css-1160xiw-MuiPaper-root-MuiDrawer-paper{
    background-color: var(--navbarBackground) !important;
    color: white !important;
    font-weight: bold !important;
}