*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  text-decoration: none;
}
a{
  color: inherit;
}

:root{
  --navbarBackground: #e48324;
  --white: #ffff ;
}